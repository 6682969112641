import request from '@/utils/request'


const login =  (unionid) => {
    return request({
        url: `/wechat/login`,
        params:{unionid},
        method: 'POST'
    })
}

const callback =  (params) => {
    return request({
        url: `/wechat/callback`,
        params,
        method: 'GET'
    })
}


const genMpLoginRequest = (params) =>{
    return request({
        url: `/wechat/mp_auth_url`,
        params,
        method: 'GET'
    })
}



export const wechat = {
    login,callback,genMpLoginRequest,
}