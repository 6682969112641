
// constantRoutes： 代表那些不需要动态判断权限的路由，如登录页、404、等通用页面。
// asyncRoutes： 代表那些需求动态判断权限并通过 addRoutes 动态添加的页面
// import { asyncRoutes, constantRoutes } from '@/router'

import {getRoutesById} from "@/api/route";
import {Promise} from "es6-promise";
import loadView from "@/views/loadView";
import {getMenuGroups} from "@/api/menu_group";
import {reactive} from "vue";


/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        // 遍历route中的meta是否存在该角色
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
    const res = []

    routes.forEach(route => {
        // 将一个对象的所有可枚举属性复制到另一个对象中，而不改变原对象。（这是一种浅拷贝）
        const tmp = { ...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                // 如果有子route则回调遍历
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res
}


const getDefaultState = ()=>{
    return reactive({
        routes: [],
        addRoutes: [],
        menuGroups:[],
        allocationRoute:[]
    })
}

// 以下为store的对象
const state = getDefaultState()

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.routes = routes
    },
    SET_MENU_GROUPS: (state, groups) => {
        state.menuGroups = groups
    },
    SET_ALLOCATION_ROUTE: (state, allocationRoute) => {
        state.allocationRoute = allocationRoute
    },
    CLEAR_STATE: ()=>{
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    // {commit} 为解构算法，原来是context，我们只从中提取出commit。
    generateRoutes({commit},userId) {

        return new Promise((resolve ,reject)=> {
            getMenuGroups().then(res => {
                const menu_groups = res.data.data
                commit('SET_MENU_GROUPS', menu_groups)
                getRoutesById(userId).then(res=>{
                    const routes = res.data.data
                    const result = formatRoute(routes)
                    commit('SET_ROUTES', result)
                    commit('SET_ALLOCATION_ROUTE',allocationRoute(result,state.menuGroups)) // 分配SideBar
                    resolve(result)
                }).catch(error=>{
                    reject(error)
                })
            }).catch(error => {
                console.log(error)
            })

        })
    },
    clearState({commit}){
        commit('CLEAR_STATE')
    },

}


// import { defineAsyncComponent } from 'vue'




/**
 * 格式化路由数据
 * @param routes
 * @returns {[]}
 */
const formatRoute = (routes)=>{

    let result = []

    routes.forEach(route=>{
        let item = {};
        item.path = route.path;
        item.name = route.component;
        item.group_id = route.group_id;

        if (route.url) item.component = loadView(route.url)
        if (route.redirect) item.redirect = route.redirect

        if(route.requiresAuth) item.meta.requiresAuth = (route.requiresAuth === 1);
        // 定义meta数据
        item.meta = []
        if(route.title) item.meta.title = route.title;
        if(route.keepAlive) item.meta.keepAlive = (route.keepAlive === 1);
        if(route.children) item.children = formatRoute(route.children);
        result.push(item)
    })
    return result
}
/**
 * 分配分组
 * @param routes
 * @param menuGroups
 * @returns {*[]}
 */
const allocationRoute = (routes,menuGroups) => {

    let result = []
    // 获取分组信息
    let menu_groups = menuGroups


    console.log("routes",routes)

    // 遍历根元素
    routes.forEach(route => {
        const temp = JSON.parse(JSON.stringify(route))
        temp.children = []
        result.push(temp)
    })


    // 分配分组
    menu_groups.forEach(group => {

        const groupId = group.id
        const groupName = group.name
        const icon = group.icon

        // 临时分组存放
        let groupTemp = {
            id: groupId,
            name: groupName,
            icon: icon,
            children: []
        }

        // 遍历分组
        routes.forEach(route => {
            if (route.children) route.children.forEach(child => {
                if (child.group_id === groupId) {
                    groupTemp.children.push(child)
                }
            })
        })

        const findResult = result.find(child => child.group_id === 1)
        if (findResult && groupTemp.children.length) findResult.children.push(groupTemp)
    })

    return result
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
