import Cookies from 'js-cookie'

const TokenKey = 'token'
const UserIdKey = 'user_id'
const EmpIdKey = 'emp_id'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function getUserId() {
    return Cookies.get(UserIdKey)
}
export function getEmpId() {
    return Cookies.get(EmpIdKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}