import {getInfo, login, setUserAvatar, user_api} from "@/api/user";
import {Promise} from "es6-promise";
import {reactive} from "vue";
import {getUserId} from "@/utils/auth";
import {getRoles, getRolesName} from "@/api/role";
import {prettyLog} from "@/utils/prettyLog";
import Cookies from "js-cookie";
import store from "@/vuex/store";



/**
 * 默认state，方便用于初始化。
 */
const getDefaultState = ()=>{
    return reactive({
        userInfo:{},
        name: '',
        empId: '',
        avatar: '',
        roles:[],
        permissions:[],
        introduction: '',
        roles_ids: []
    })
}

const state = getDefaultState()

const mutations = {
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_USERNAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        // 头像读取附带数据，强制刷新缓存
        avatar = avatar + "?timestamp=" + new Date().getTime(); // 设置时间戳，强制刷新缓存。
        state.avatar = avatar
    },
    SET_ROLES_IDS: (state, roles) => {
        state.roles_ids = roles.split(",")
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_EMP_ID: (state, empId) => {
        state.empId = empId
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions
    },
    CLEAR_STATE: ()=>{
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    // user login
    login({commit},userAccount) {
        const { phoneNumber, password } = userAccount
        return new Promise((resolve, reject) => {
            login(phoneNumber.trim(),password).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    clearState({commit}){
        commit('CLEAR_STATE')
    },


    // get user info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                const { data } = response

                if (!data) {
                    reject('验证失败，请再次尝试登录以获取登录信息！')     }


                let { roles, userName, avatar, empId, id} = data.data

                commit('SET_USERINFO', data.data) // 存储用户个人信息

                // roles must be a non-empty array
                if (!roles) {
                    reject('getInfo: roles must be a non-null array!')
                }

                if(!avatar){
                    avatar = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                }


                // 获取role的名称
                getRolesName(roles.split(",")).then(res=>{
                    commit('SET_ROLES', res.data.data)
                }).catch(error => {console.log(error)})

                // 获取用户权限
                user_api.getPermissions(id).then(res=>{
                    commit('SET_PERMISSIONS', res.data.data)
                }).catch(error => {console.log(error)})

                commit('SET_ROLES_IDS', roles)
                commit('SET_USERNAME', userName)
                commit('SET_AVATAR', avatar)
                commit('SET_EMP_ID', empId)

                 // 如果登陆了就初始化数据
                store.dispatch('employee/formatDepJobs')
                store.dispatch('classes/getOptions')

                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //
    //
    // 设置头像
    setAvatar({ commit },avatar) {
        return new Promise((resolve,reject) => {
            setUserAvatar({
                userId:getUserId(),
                avatarUrl:avatar
            }).then(res => {

                commit('SET_AVATAR', avatar)
                resolve(res)
            }).catch(error => {
                console.log("头像设置失败",error)
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}