import {reactive} from "vue";

const getters = reactive({
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    empId: state => state.user.empId,
    permissions: state => state.user.permissions,
    introduction: state => state.user.introduction,
    roles_ids: state => state.user.roles_ids,
    roles: state => state.user.roles,// 获取用户的roles
    permission_routes: state => state.permission.routes,
    menuGroups: state => state.permission.menuGroups,
    allocationRoute: state => state.permission.allocationRoute,
    departments: state => state.employee.departments,
    jobs: state => state.employee.jobs,
    treeDepJob: state => state.employee.tree, // 原始树形数据
    faculty_options: state => state.classes.faculty_options, // 学部及班级选项
    classes: state => state.classes.classes // 所有班级数据

})
export default getters