import request from '@/utils/request'


export function getRoutesByRole(roles) {
    const encodedRoles = roles.join(',');
    return request({
        url: `/route/getRoute/${encodedRoles}`,
        method: 'GET'
    })
}

export function getRoutesById(userId) {
    return request({
        url: `/route/getRoute`,
        params:{userId},
        method: 'GET'
    })
}