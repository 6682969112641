import request from '@/utils/request'
import qs from "qs";


/**
 * 用户登出
 *
 */

export function logout() {

    return request({
        url: '/user/logout',
        method: 'DELETE'
    })
}

export function login(phoneNumber,password){
    return request({
        url:'/user/login',
        params:{phoneNumber,password},
        method:"GET",
    })
}

export function register(params){

    return request({
        url:`/user/register`,
        data:params,
        method:"POST",
    })
}

export function registerBatch(data){

    return request({
        url:`/user/batch/register`,
        data,
        method:"POST",
    })
}

export function getInfo(){
    return request({
        url:'/user/info',
        method:"GET"
    })
}

export function hasPhoneNumber(phoneNumber){
    return request({
        url:'/user/check-phone',
        params:{phoneNumber},
        method:"GET"
    })
}

export function deleteUser(arr){
    const encodedRoles = arr.join('&ids=');
    return request({
        url:`/user/delete?ids=${encodedRoles}`,
        method:"DELETE",
    })
}

export function setUserAvatar(params){

    return request({
        url:`/user/profile/avatar`,
        params,
        method:"POST",
    })
}

function setUserName(params){

    return request({
        url:`/user/profile/userName`,
        params,
        method:"POST",
    })
}


function getUserList(params){
    return request({
        url:`/user/list`,
        params,
        method:"GET",
    })
}


export function bindOpenId(params){
    return request({
        url:`/user/bind`,
        params,
        method:"get",
    })
}

function getPermissions(id){
    return request({
        url:`/user/permissions`,
        params:{
            id
        },
        method:"GET",
    })
}

export const user_api = {
    hasPhoneNumber,setUserName,getUserList,getPermissions
}