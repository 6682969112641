import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
import { Message } from "@element-plus/icons-vue";
import store from "@/vuex/store";
import { useRouter } from "vue-router";
import { computed, reactive, toRaw } from "vue";
export default {
  __name: 'SideBar',
  setup(__props) {
    const routes = computed(() => store.getters.allocationRoute);
    const router = useRouter();
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        height: "calc(100vh - 60px)"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "default-active": _unref(router).currentRoute.value.path,
          "default-openeds": ['0']
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, root => {
            return _openBlock(), _createElementBlock("div", {
              key: root.id
            }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(root.children, (route, index) => {
              return _openBlock(), _createBlock(_component_el_sub_menu, {
                key: route.id,
                index: index + ''
              }, {
                title: _withCtx(() => [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(route.icon)))]),
                  _: 2
                }, 1024), _createTextVNode(" " + _toDisplayString(route.name), 1)]),
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.children, (child, child_index) => {
                  return _openBlock(), _createBlock(_component_router_link, {
                    key: child_index,
                    style: {
                      "text-decoration": "none"
                    },
                    to: root.path + '/' + child.path
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_menu_item, {
                      index: root.path + '/' + child.path
                    }, {
                      default: _withCtx(() => [_createTextVNode(_toDisplayString(child.meta.title), 1)]),
                      _: 2
                    }, 1032, ["index"])]),
                    _: 2
                  }, 1032, ["to"]);
                }), 128))]),
                _: 2
              }, 1032, ["index"]);
            }), 128))]);
          }), 128))]),
          _: 1
        }, 8, ["default-active"])]),
        _: 1
      });
    };
  }
};